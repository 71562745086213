import IndustrialProjectTemplate from "./../SampleData/industrialProjectTemplate.json";
import BaseHttpService from "./base-http";
import { FeaturedItem } from "../Classes/FeatureBase";
import { ICreateNewAnalysis } from "../Interfaces/ICreateNewAnalysis";
import { IFeatureCounty } from "../Interfaces/IFeatureCounty";
import { IProject } from "../Interfaces/IProject";
import { ISiteAdvisorUser } from "../Interfaces/IUser";
import { IWorkspace } from "../Interfaces/IWorkspace";

class SiteAdvisorService extends BaseHttpService {
  BASE_URL = process.env.REACT_APP_SITE_API;
  ATLAS_URL = process.env.REACT_APP_ATLAS_API;
  userId = null as number | null;


  async loadFeature(projectid: number) {
    return returnFeatureItem(
      await this.get(`${this.BASE_URL}/features/${projectid}`)
    );
  }

  async loadWorkspace(): Promise<IWorkspace> {
    const res = await this.get(
      `${this.BASE_URL}/workspace?workspace=industrial`
    );

    if (res) {
      return res.data;
    }
    return {} as IWorkspace;
  }

  async loadMSA(): Promise<any> {
    const res = await this.get(`${this.ATLAS_URL}/geographylist/cbsaplus`);

    if (res) {
      return res.data;
    }
    return;
  }

  async createNewAnalysis(newAnalysis: ICreateNewAnalysis) {
    return await this.post(`${this.BASE_URL}/project`, {
      ...newAnalysis,
      userid: this.userId,
      field: "industrial"
    });
  }

  async updateAnalysis(id: number, updateAnalysis: ICreateNewAnalysis) {
    return await this.post(`${this.BASE_URL}/project/${id}`, {
      ...updateAnalysis,
      userid: this.userId,
      field: "industrial"
    });
  }

  async updateProperties(id: number, property_upload: any[]) {
    return await this.post(`${this.BASE_URL}/properties/${id}`, { properties: [ ...property_upload ] });
  }

  async loadProjectTemplate() {
    return delay(300, null).then(() => IndustrialProjectTemplate);
  }

  async loadAnalysisList(): Promise<IProject[]> {
    return returnAnalysisList(
      await this.get(`${this.BASE_URL}/projects/${this.userId}`)
    );
  }

  async loadProject(id: number): Promise<IProject> {
    const res = await this.get(`${this.BASE_URL}/project/${id}`);

    if (res && res.status === 403) {
      this.history.push("/");
      return { error: "Unauthorized" } as IProject;
    }
    if (res && res.status === 204) {
      this.history.push("/");
      return { error: "Project has been deleted" } as IProject;
    }
    if (res && res.status === 200) {
      return { ...res.data, projectid: id, scoreWeights: res.data.scoreWeights ? res.data.scoreWeights : [] };
    }

    return {} as IProject;
  }

  async getUserInfo(): Promise<ISiteAdvisorUser> {
    const res = returnSiteAdvisorUser(
      await this.get(`${this.BASE_URL}/userinfo`)
    );

    this.userId = res.userid;
    return res;
  }

  async saveProject(id: number, data: any) {
    return await this.post(`${this.BASE_URL}/project/${id}`, { ...data });
  }

  async deleteProject(id: string | number) {
    const res = await this.delete(`${this.BASE_URL}/project/${id}`);

    if (res) {
      return res.data;
    }
    return;
  }

  async acceptTerms(id: string | number): Promise<string | null> {
    const res = await this.post(`${this.BASE_URL}/projects/${id}/terms`);

    if (res) {
      return new Date(res.data).toISOString();
    }
    return null;
  }
}

export default SiteAdvisorService;

function returnFeatureItem(res: any) {
  if (res && res.status === 200) {
    return res.data.features.map((d: any) => {
      return {
        ...d
      } as FeaturedItem<IFeatureCounty>;
    });
  } else {
    return {} as FeaturedItem<IFeatureCounty>;
  }
}
function delay(t: number, v: any) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

function returnSiteAdvisorUser(res: any) {
  if (res && res.status === 200) {
    return {
      ...res.data,
      latitude: parseFloat(res.data.latitude),
      longitude: parseFloat(res.data.longitude)
    } as ISiteAdvisorUser;
  } else {
    return {} as ISiteAdvisorUser;
  }
}

function returnAnalysisList(res: any) {
  if (res && res.status === 200) {
    return [
      ...res.data.map((proj: IProject) => {
        return { ...{ id: proj.projectid }, ...proj };
      })
    ] as IProject[];
  } else {
    return [] as IProject[];
  }
}
