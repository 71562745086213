import { ProviderState } from "./Context";
import { FeaturedCountyLayer } from "../Classes/FeaturedCountyLayer";
import { FeaturedPropertyLayer } from "../Classes/FeaturedPropertyLayer";
import { ScoringType } from "../Interfaces/enumScoring";
import { ICreateNewAnalysis } from "../Interfaces/ICreateNewAnalysis";
import { IProject } from "../Interfaces/IProject";
import { IScoreWeight } from "../Interfaces/IScoreWeight";
import { IWorkspace } from "../Interfaces/IWorkspace";
import { buildFilters, filterData } from "../Utils/Utils";

export const  setActiveLayers = (ctx:ProviderState, layer:string) => {
  const found = ctx.activeLayers.find((l) => l === layer);
  let activeLayers = [];

  if (found) {
    activeLayers = [ ...ctx.activeLayers.filter((l) => l !== layer) ];
  } else {
    activeLayers = [ ...ctx.activeLayers, layer ];
  }
  return activeLayers;
};

export const updateScore = async (ctx:ProviderState, scores: IScoreWeight[], scoreType:ScoringType) => {
  const { featuredLayer, featuredPropertyLayer } = ctx;

  featuredPropertyLayer?.calculateScoreWeights(scores, scoreType);
  featuredLayer?.calculateScoreWeights(scores, scoreType);
  return { featuredLayer, featuredPropertyLayer, scoreType };
};


export const  updateLayerStyle = (ctx:ProviderState, styleByField: any) => {
  const { featuredLayer } = ctx;

  featuredLayer!.styleByField = styleByField;
  featuredLayer?.generateColorRange(styleByField);
  return ctx;
};


export const updateProjectMSA = async (ctx:ProviderState, id: number, updateAnalysis: ICreateNewAnalysis) => {
  const { featuredLayer, userProject, service, scoreType, workspace } = ctx;

  const data = {
    ...userProject,
    ...updateAnalysis
  };

  await service.updateAnalysis(id, data );
  if (featuredLayer) {
    featuredLayer.buildFeaturedCounties(await service.loadFeature(id), userProject);
    const userProperties = userProject.properties?.map((properties: any) => { return { properties }; });
    const newFilters =  buildFilters(
      [ ...userProperties ? userProperties : [], ...featuredLayer.featuredCounties.map(county => county.data) ],
      workspace.filters
    );

    featuredLayer.calculateScoreWeights(ctx.workspace.scoreWeights, scoreType);
    filterData({ featuredItems: featuredLayer.featuredCounties, filters: newFilters });
    userProject.baseGeographies = updateAnalysis.baseGeographies;
    return { featuredLayer, filters: newFilters, userProject };
  }
};

export const updateFilters = (ctx:ProviderState, obj: any) => {
  const { featuredLayer, filters } = ctx;
  const idx = filters.findIndex((f: any) => f.field === obj.field);

  filters[idx].values = obj.range;
  filterData({ featuredItems: featuredLayer!.featuredCounties, filters });
  return ctx;
};

export const updateProperties = async (ctx:ProviderState, property_upload: any[]) => {
  const { featuredLayer, scoreType } = ctx;

  const res = await ctx.service.updateProperties(ctx.userProject.projectid, [ ...property_upload ]);

  if (res && featuredLayer) {
    const featuredPropertyLayer = new FeaturedPropertyLayer({ featuredProperty: res.data.rows, featuredCounties: featuredLayer.featuredCounties, workspace: ctx.workspace, userProject: ctx.userProject });
    const newFilters = buildFilters(
      [ ...featuredPropertyLayer.featuredProperties.map(prop => prop.data), ...featuredLayer.featuredCounties.map(county => county.data) ],
      ctx.workspace.filters,
      ctx.userProject.fields
    );

    featuredLayer.calculateScoreWeights(ctx.workspace.scoreWeights, scoreType);
    filterData({ featuredItems: featuredLayer.featuredCounties, filters: newFilters });
    return { featuredLayer, featuredPropertyLayer, filters: newFilters };
  }
  return;
};

export const updateUnSelected = (ctx:ProviderState, unselected: string, type: string) => {
  const { scoreType, featuredLayer, featuredPropertyLayer, workspace, userProject, workspace: { scoreWeights } } = ctx;

  if (type === "counties" && featuredLayer) {
    const foundInUnselected = featuredLayer.unselectedFeatures?.find((county) => county.data.properties.name === unselected);

    if (foundInUnselected) {
      featuredLayer.featuredCounties = [ ...featuredLayer.featuredCounties, foundInUnselected ];
      featuredLayer.unselectedFeatures = featuredLayer.unselectedFeatures?.filter((county) => county.data.properties.name !== unselected);
    } else {
      const removed = featuredLayer?.featuredCounties.filter((county) => unselected === county.data.properties.name);

      featuredLayer.featuredCounties =  [ ...featuredLayer.featuredCounties.filter((county) => county.data.properties.name !== unselected) ];
      featuredLayer.unselectedFeatures = [ ...featuredLayer.unselectedFeatures, ...removed ];
    }
  } else if (type === "properties" && featuredPropertyLayer){
    const foundInUnselected = featuredPropertyLayer.unselectedProperties?.find((property) => property.data.properties.property_name === unselected);
    
    if (foundInUnselected) {
      featuredPropertyLayer.featuredProperties = [ ...featuredPropertyLayer.featuredProperties, foundInUnselected ];
      featuredPropertyLayer.unselectedProperties = featuredPropertyLayer.unselectedProperties.filter((property) => property.data.properties.property_name !== unselected);
    } else {
      const removed =  featuredPropertyLayer.featuredProperties.filter((property) => unselected ===  property.data.properties.property_name);
      
      featuredPropertyLayer.featuredProperties =  featuredPropertyLayer.featuredProperties.filter((property) =>  unselected !==  property.data.properties.property_name);
      featuredPropertyLayer.unselectedProperties = [ ...featuredPropertyLayer.unselectedProperties, ...removed ];
    }
  }
  const filters = buildFilters([ ...featuredLayer ? featuredLayer.featuredCounties.map(county => county.data) : [] ], workspace.filters, userProject.fields);

  featuredPropertyLayer?.calculateScoreWeights(scoreWeights, scoreType);
  featuredLayer?.calculateScoreWeights(scoreWeights, scoreType);
  return { featuredLayer, featuredPropertyLayer, filters };
};

export const resetFilters = (ctx:ProviderState) => {
  const { featuredLayer, filters } = ctx;

  filters.forEach((f) => {
    f.values = f.range;
  });

  filterData({ featuredItems: featuredLayer!.featuredCounties, filters });
  return ctx;
};

export const resetScores = (ctx:ProviderState) => {
  const { featuredLayer, featuredPropertyLayer, workspace, userProject: { scoreWeights }, scoreType } = ctx;

  scoreWeights.forEach((score, idx) => {
    score.value = workspace.scoreWeights[idx].value;
  });
  featuredPropertyLayer?.calculateScoreWeights(scoreWeights, scoreType);
  featuredLayer?.calculateScoreWeights(scoreWeights, scoreType);
  return ctx;
};

export const createNewAnalysis = async(ctx:ProviderState, newAnalysis: ICreateNewAnalysis) => {
  const res = await ctx.service.createNewAnalysis(newAnalysis);

  if (res.status === 200){
    return res.data;
  }
  return { error: "Could not create analysis" };
};

export const loadMSAs = async (ctx:ProviderState) => {
  const MSA = await ctx.service.loadMSA(); // then grab all projects for user.

  return { ...ctx, MSA };
};


export const loadProjects = async (ctx:ProviderState) => {
  const projects = await ctx.service.loadAnalysisList();

  return { ...ctx, userProject: {}, projects };
};

export const saveProject = async (ctx:ProviderState) => {
  const { filters, featuredLayer, featuredPropertyLayer, userProject } = ctx;
  const data = {
    ...userProject,
    ...{ filters },
    ...{ unselectedFeatures: featuredLayer?.unselectedFeatures.map((u)=> u.id)  },
    ...{ unselectedProperties: featuredPropertyLayer?.unselectedProperties.map((u)=> u.id) }
  };

  await ctx.service.saveProject(ctx.userProject.projectid, data);

  return ctx;
};  


export const deleteProject = async (ctx:ProviderState, id:number|string) => {
  const deleted = await ctx.service.deleteProject(id);

  if (deleted === "deleted") {
    return { projects: ctx.projects.filter((p)=> p.id !== id) };
  }
  return { error: "error" };
};


const loadPropertyLayer = (userProject:IProject, workspace:IWorkspace, featuredLayer:FeaturedCountyLayer)=> {
  let featuredPropertyLayer: FeaturedPropertyLayer;
  let filters: {
    range: number[];
    values: number[];
    field: string;
    label: string;
    description: string;
  }[] = [];

  if (userProject.properties && featuredLayer) {
    featuredPropertyLayer = new FeaturedPropertyLayer({
      featuredProperty: userProject.properties,
      featuredCounties: featuredLayer.featuredCounties,
      workspace,
      userProject
    });
    filters = buildFilters([ ...featuredPropertyLayer.featuredProperties.map(item => { return { properties: item.data }; }), ...featuredLayer.featuredCounties.map(county => county.data) ], workspace.filters, userProject.fields);
    return { filters, featuredPropertyLayer };
  } else {
    filters = buildFilters([ ...featuredLayer.featuredCounties.map(county => county.data) ], workspace.filters, userProject.fields);
    return { filters, featuredPropertyLayer: null };
  }
};

export const loadSiteDashboard = async (ctx:ProviderState, id: number) => {
  const userProject = await ctx.service.loadProject(id);

  if (userProject.error){
    return { error: userProject.error };
  }
  if (userProject ){
    const featuredItems = await ctx.service.loadFeature(id);
    const workspace = await ctx.service.loadWorkspace();
  
    userProject.scoreWeights = [ ...workspace.scoreWeights.map((w) => {
      return { ...w, ...userProject.scoreWeights.find((u) => u.field === w.field) };
    }) ];
    
    const featuredLayer = new FeaturedCountyLayer({
      featuredItems: [ ...featuredItems ],
      workspace,
      userProject
    });

    const { filters, featuredPropertyLayer } = loadPropertyLayer(userProject, workspace, featuredLayer);

    filterData({ featuredItems: featuredLayer.featuredCounties, filters });
    return {
      ctx,
      workspace,
      featuredPropertyLayer,
      userProject,
      featuredLayer,
      filters
    };
  }
};

export const acceptTerms = async (ctx:ProviderState, id: number) => {
  const accepted = await ctx.service.acceptTerms(id);

  if (accepted){
    const { userProject } = ctx;

    userProject.terms_accepted = accepted;
    return { ctx, userProject };
  }
  return { ctx };
};
