import { FeaturedItem } from "./FeatureBase";
import { ScoringType } from "../Interfaces/enumScoring";
import { IFeatureCounty } from "../Interfaces/IFeatureCounty";
import { IFeatureGeoJson } from "../Interfaces/IFeatureGeoJson";
import { IFeatureProperty } from "../Interfaces/IFeatureProperty";
import { IProject } from "../Interfaces/IProject";
import { IScoreWeight } from "../Interfaces/IScoreWeight";
import { IWorkspace } from "../Interfaces/IWorkspace";
import { calculateScores } from "../Utils/scoreCalculate";

export class FeaturedPropertyLayer {
  featuredProperties: FeaturedItem<IFeatureGeoJson<IFeatureProperty>>[] = [];
  unselectedProperties:FeaturedItem<IFeatureGeoJson<IFeatureProperty>>[] = [];
  private featuredCounties: FeaturedItem<IFeatureCounty>[] = [];
  constructor({ featuredProperty, featuredCounties, workspace, userProject }: { featuredProperty: IFeatureProperty[]; featuredCounties: FeaturedItem<IFeatureCounty>[]; workspace: IWorkspace; userProject:IProject}) {
    this.buildingQuality(featuredProperty);
    this.featuredProperties = featuredProperty
      .filter((f) => !userProject.unselectedProperties?.find((u) => u === f.id))
      .map(
        (data, id) => {
          const geojsonStructure = { 
            type: "Feature",
            properties: { ...data, id: id + 1 },
            geometry: {
              type: "Point",
              coordinates: [ data.longitude, data.latitude ]
            } };

          return new FeaturedItem(geojsonStructure, id + 1);
        }
      );
    this.unselectedProperties = featuredProperty.filter((f) => userProject.unselectedProperties?.find((u) => u === f.id))
      .map(
        (data, id) => {
          const geojsonStructure = { 
            type: "Feature",
            properties: { ...data, id: id + 1 },
            geometry: {
              type: "Point",
              coordinates: [ data.longitude, data.latitude ]
            } };

          return new FeaturedItem(geojsonStructure, id + 1);
        }
      );
    this.featuredCounties = JSON.parse(JSON.stringify(featuredCounties));
    this.calculateScoreWeights(userProject.scoreWeights, ScoringType.RELATIVE);
  }

  buildingQuality(featuredProperty: IFeatureProperty[]) {
    const classScore = (buildingClass: string) => buildingClass === "A" ? 100 : buildingClass === "B" ? 60 : 30;
    const years = featuredProperty.filter(item => item.building_class && item.year_built).map(item => parseInt(item.year_built));
    const min = Math.min(...years);
    const max = Math.max(...years);
    const range = max - min;

    featuredProperty.filter(item => item.building_class && item.year_built).forEach(item => {
      const yearScore = (parseInt(item.year_built) - min) / range;

      item.building_quality = (yearScore + classScore(item.building_class)) / 2;
    });
  }

  calculateScoreWeights(scores: IScoreWeight[], national:ScoringType ) {
    const categories = [
      {
        group: "labor",
        weight: 0.6
      },
      {
        group: "real_estate",
        weight: 0.3
      },
      {
        group: "risk",
        weight: 0.1
      }
    ];

    calculateScores([ ...this.featuredProperties, ...this.featuredCounties ], scores, categories, national);
  }
}
 
