
export class FeaturedItem<TData> {
  id: number|string = 0;
  type: string = "Feature";
  data: TData;
  display = {
    visible: true,
    selected: true,
    color: "",
    total_color: ""
  };

  constructor(data:TData, id:number|string){
    this.data = data;
    this.id = id;
  }
}
