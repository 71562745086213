import { IFilterData } from "../Interfaces/IFilterData";

export function sortCounty(a: any, b: any) {
  return a.data.properties.total_score < b.data.properties.total_score
    ? 1
    : a.data.properties.total_score > b.data.properties.total_score
      ? -1
      : 0;
}
export function isEmpty(obj: {}) {
  return Object.keys(obj).length === 0;
}

export function UploadCsvFile() {}

export interface IGenerateColors {
  properties: any;
  field: string;
  colorArr?: string[];
  numArr?: number[];
}

export function generateColor({
  properties,
  field,
  colorArr = [ "#F0190C", "#F8B720", "#1D9800" ],
  numArr = [ 0, 46, 57, 100 ]
}: IGenerateColors) {
  const value = properties[field as keyof any];

  if (value === "N/A") {
    return "#8f8f8f";
  }
  return colorArr[findColorIndex(value, numArr)];
}


export function findColorIndex(value: any, numArr: number[]) {
  return numArr.findIndex((l: any, i: number, arr: any) => {
    return value >= l && arr[i + 1] && value <= arr[i + 1];
  });
}

export function round(value: number, precision: number, up: boolean = true) {
  var multiplier = Math.pow(10, precision || 0);

  return up
    ? Math.ceil(Math.round(value * multiplier) / multiplier)
    : Math.floor(Math.round(value * multiplier) / multiplier);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/* eslint-disable no-unused-vars */
export function getComparator<Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key
): (
  a: { [key in Key]: string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [ el, index ] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
/* eslint-disable no-unused-vars */

export function buildFilters(items: any[], filters: {
  field: string;
  label: string;
  range: number[];
  description: string;
}[], userFilters?: {
  field: string;
  values: number[];
}[]) {
  return filters.map((f) => {
    const userValues = userFilters?.find(
      (u) => u.field === f.field
    );

    const range = minMax(items, f.field);

    // if user has saved filters, load the saved filters,
    // otherwsie set the range to the min/max values of the field
    return {
      ...f,
      range: [ Math.floor(range[0]), Math.ceil(range[1]) ],
      values: userValues
        ? userValues.values
        : [ Math.floor(range[0]), Math.ceil(range[1]) ]
    };
  });
}

function minMax(items: any[], field: string) {
  return items.reduce((acc, val) => {
    acc[0] =
      acc[0] === undefined || val.properties[field] < acc[0]
        ? val.properties[field]
        : acc[0];
    acc[1] =
      acc[1] === undefined || val.properties[field] > acc[1]
        ? val.properties[field]
        : acc[1];
    return acc;
  }, []);
}


export function unitsLabel(text:string, units?:string){
  const formattedValue = new Intl.NumberFormat( "en-US", { 
    minimumFractionDigits: fractionDigits(units),  
    maximumFractionDigits: fractionDigits(units)  
  }).format(parseFloat(text));

  if (units === "percent"){
    return `${formattedValue}%`;
  } 
  if (units === "currency" || units === "kWh"){
    return `$${formattedValue}`;
  }
  return formattedValue;
}

function fractionDigits(units?:string){
  if (units === "percent"){
    return 1;
  } else if (units === "kWh"){
    return 5;
  } else if (units === "currency" || units === "twoDecimal"){
    return 2;
  }
  return 0;
}


export const filterData = ({ featuredItems, filters }: IFilterData) => {
  featuredItems.map((feature: any) => {
    const validate = filters.map((filter) => {
      const filteredVal = parseFloat(feature.data.properties[filter.field]);
      const lowFilterVal = filter.values[0];
      const highFilterVal = filter.values[1];

      return filteredVal >= lowFilterVal && filteredVal <= highFilterVal;
    });

    return  feature.display.visible = validate.indexOf(false) < 0;
  });
};
