import { useAuth0 } from "@auth0/auth0-react";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../Context/Context";
import { HelpPage } from "../../Pages/HelpPage";
import { SignedTerms } from "../../Pages/TermsCond";

const ResponsiveAppBar = () => {
  const { user, logout } = useAuth0();
  const { userProject } = useContext(Context);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ anchorHelpEl, setAnchorHelpEl ] = useState<null | HTMLElement>(null);
  const [ menuSelected, setMenuSelected ] = useState<null|string>(null);
  const open = Boolean(anchorEl);
  const openHelp = Boolean(anchorHelpEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick = (event: any) => {
    setMenuSelected(event.currentTarget.id);
    setAnchorHelpEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    logout({ returnTo: window.location.origin });
  };

  return (
    <AppBar
      position="static"
      className="appBarDiv appBarDiv_siteAdvisor"
      sx={ {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      } }
    >
      <Link to="/analysislist">
        <div className="appbar_logo" />
      </Link>
      <div style={ { display: "flex" } }>
        <IconButton
          aria-label="help page"
          component="span"
          sx={ { color: "white", borderRadius: "50%" } }
          id="help-page"
          onClick={ handleMenuClick }
        >
          <HelpCenterRoundedIcon />
        </IconButton>
        <Menu
          id="helper-menu"
          anchorEl={ anchorHelpEl }
          open={ openHelp }
          onClose={ () => setAnchorHelpEl(null) }
          MenuListProps={ {
            "aria-labelledby": "basic-button"
          } }
          sx={ { padding: 0 } }
        >
          { menuSelected === "help-page" ? <HelpPage close={ () => setAnchorHelpEl(null) } /> : <SignedTerms user={ { ...user } } acceptedDate={ userProject.terms_accepted } close={ () => setAnchorHelpEl(null) }/> }
        </Menu>
        <Avatar
          variant="rounded"
          alt={ user?.name }
          src={ user?.picture }
          onClick={ handleClick }
          sx={ { margin: "0px 8px" } }
        />
        <Menu
          id="basic-menu"
          anchorEl={ anchorEl }
          open={ open }
          onClose={ () => setAnchorEl(null) }
          MenuListProps={ {
            "aria-labelledby": "basic-button"
          } }
        >
          { /* { userProject.terms_accepted && <MenuItem 
            id="policy-page"
            onClick={ handleMenuClick }
            sx={ { padding: "0px 10px" } }>
            Terms
          </MenuItem> } */ }
          <MenuItem onClick={ handleClose } sx={ { padding: "0px 10px" } }>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </AppBar>
  );
};

export default ResponsiveAppBar;

export const CreateNewAnalysisBar = () => {
  return (
    <AppBar
      position="static"
      className="appBarDiv"
      style={ { padding: 0 } }
    >
      <div style={ { width: "250px", backgroundColor: "#0c9ed9", padding: 0 } }>
        <Toolbar variant="dense">
          <Typography component="div">CREATE NEW ANALYSIS</Typography>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export const AnalysisBar = ({ analysis }: { analysis: any }) => {
  const AnalysisType = () => {
    const markets = analysis.baseGeographies?.length > 0;
    const properties = analysis.properties?.length > 0;

    return markets && properties
      ? "Markets & Properties"
      : markets
        ? "Markets only"
        : "none";
  };

  return (
    <AppBar position="static" className="appBarDiv appBarDiv_dashboard">
      <div>
        <Toolbar variant="dense">
          <Typography component="div">ANALYSIS DASHBOARD</Typography>
        </Toolbar>
        <Toolbar variant="dense">
          <div style={ { flexDirection: "column" } }>
            <Typography variant="caption" component="div">
              Analysis Name
            </Typography>
            <Typography variant="caption" component="div">
              { analysis.name }
            </Typography>
          </div>

          <div>
            <Typography variant="caption" component="div">
              Analysis Type
            </Typography>
            <Typography variant="caption" component="div">
              { AnalysisType() }
            </Typography>
          </div>
          <div>
            <Typography variant="caption" component="div">
              MSAs
            </Typography>
            <Typography variant="caption" component="div">
              { analysis.baseGeographies ? analysis.baseGeographies.length : 0 }
            </Typography>
          </div>
          <div>
            <Typography variant="caption" component="div">
              Counties
            </Typography>
            <Typography variant="caption" component="div">
              { analysis.counties ? analysis.counties.length : 0 }
            </Typography>
          </div>
          <div style={ { flexDirection: "column" } }>
            <Typography variant="caption" component="div">
              Properties
            </Typography>
            <Typography variant="caption" component="div">
              { analysis.properties ? analysis.properties.length : 0 }
            </Typography>
          </div>
          <div style={ { flexDirection: "column" } }>
            <Typography variant="caption" component="div">
              Last Updated
            </Typography>
            <Typography variant="caption" component="div">
              { moment(analysis.modified).format("MM/DD/YYYY") }
            </Typography>
          </div>
        </Toolbar>
      </div>
    </AppBar>
  );
};
