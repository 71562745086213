export const Logo = ({
  animate = false
}: {
  animate?: boolean | undefined;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 377.08 214.62"
    height="42"
  >
    <path
      d="M1669.69-47.83H1336a21.71,21.71,0,0,0-21.68,21.7V145.15a21.7,21.7,0,0,0,20.37,21.63H1671a21.71,21.71,0,0,0,20.37-21.68V-26.13A21.71,21.71,0,0,0,1669.69-47.83Z"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <rect
      className={ `blue${animate ? "-animate" : ""}` }
      x="6"
      y="177.24"
      width="365.08"
      height="15.69"
      fill="#0c9ed9"
    />
    <rect
      className={ `yellow${animate ? "-animate" : ""}` }
      x="6"
      y="161.27"
      width="365.08"
      height="15.97"
      fill="#ffd400"
    />
    <path
      className={ `red${animate ? "-animate" : ""}` }
      d="M1335,160.79h335.61a15.69,15.69,0,0,0,14.74-15.64v-.05H1320.29v.05A15.68,15.68,0,0,0,1335,160.79Z"
      transform="translate(-1314.29 47.83)"
      fill="#ed1b34"
    />
    <path
      d="M1669.69-41.83H1336a15.69,15.69,0,0,0-15.68,15.7V113.46h365.08V-26.13A15.69,15.69,0,0,0,1669.69-41.83Z"
      transform="translate(-1314.29 47.83)"
      fill="#25408f"
    />
    <path
      d="M1455.64,49.26c0-10.35-5.46-16.38-9.8-16.38a7.34,7.34,0,0,0-5.91,2.57c-2.92,3.29-4,7.23-4,14.09,0,5.46.38,9,2.93,13.07a7.87,7.87,0,0,0,7.42,4.06c6.2,0,9.41-7.81,9.41-17.41m5-14.57c4.41,4.42,6.5,9.32,6.5,14.85a21.62,21.62,0,0,1-5.84,14.31,20.88,20.88,0,0,1-15.79,6.77c-5.94,0-11-1.71-15.25-6.77-4.13-4.9-5.08-8.85-5.08-14.59a21,21,0,0,1,7.9-15.61,20.35,20.35,0,0,1,13.35-4.52,19.94,19.94,0,0,1,14.21,5.56"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1565.17,42.31c2,0,3.11-.57,3.11-1.32,0-2.82-.94-8.37-5.83-8.37-4.61,0-7.44,3.39-8.66,9.69Zm-11.66,6.3c0,9.6,6.87,15.24,12.23,15.24,5.83,0,9.39-2.46,11.38-5.46.38-.66.75-.66,1.14-.47l1.39.84c.48.28.67.66,0,1.88a18.62,18.62,0,0,1-17.11,10c-6,0-10.25-1.31-13.54-5.07-4.33-5-5.28-9.7-5.28-15.53,0-5.16.59-8.66,4.62-13.93,4-5.07,8.18-7,16.37-7,10.72,0,14.21,11.2,14.21,15.07,0,1.4-1,1.5-2.65,1.5h-22.76Z"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1590.08,45.32c0-4.33-.74-5.83-3.57-7.33l-1.68-1c-.56-.19-.75-.37-.75-.76v-.56c0-.37.19-.56.75-.84l11.37-5.37a4.66,4.66,0,0,1,1.51-.38c.57,0,.75.58.85,1.15l.84,6.76h.38c3.2-5.27,7.15-8.47,11.29-8.47a4.6,4.6,0,0,1,4.88,4.71,5.45,5.45,0,0,1-5.07,5.08,7.26,7.26,0,0,1-3.21-.75,4.23,4.23,0,0,0-2.62-.76,4.92,4.92,0,0,0-4,2.73A10.73,10.73,0,0,0,1600,44.2V62.34c0,3,.76,4,2.64,4h4.15c.55,0,.75.19.75.65v2.18c0,.46-.2.66-.67.66-.65,0-4.79-.29-11.67-.29-6.57,0-10.24.29-11,.29-.47,0-.66-.2-.66-.66V67.06c0-.58.19-.77.83-.77h3.31c1.5,0,2.43-.74,2.43-2.54Z"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1618.87,57.65c-.09-.38,0-.67.27-.77l1.79-.55c.38-.1.66-.1.85.28l3.1,5.27c1.7,2.83,3.49,5.06,8.1,5.06,3.67,0,6.67-2.23,6.67-5.73,0-3.76-2.44-5.47-9-7.34-6.4-1.87-11.49-5.07-11.49-11.76a11.45,11.45,0,0,1,4.81-9.87,15.56,15.56,0,0,1,9.77-3.11,18.27,18.27,0,0,1,9.13,2.08c1.41.74,1.69,1.13,1.78,1.67l1.24,6.42a1.33,1.33,0,0,1-.1,1l-1.79.84a.58.58,0,0,1-.75-.38l-4.53-5.07a7.6,7.6,0,0,0-5.91-2.9c-3.48,0-6.5,2.06-6.5,5.35,0,3.86,2.83,5,8.47,6.48,4.34,1.12,7,2.26,9.41,4.89,2.07,2.07,2.82,4.33,2.82,7.54,0,7.89-6.4,13.54-15.7,13.54-5,0-9.13-1.6-10.18-2.45a2.63,2.63,0,0,1-.74-1Z"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1539.72,66.29H1536c-.94,0-1.32-.74-1.5-2.44a80,80,0,0,1-.2-8.11V45.6c0-7.81,0-13.83.2-15.32.09-.77-.2-1.15-.76-1.15a6.59,6.59,0,0,0-2.26.58c-1.61.64-10.91,3.94-12.23,4.5-.47.2-.66.39-.66.76v1c0,.36.1.65.94.84,4.33,1.14,4.9,3,4.9,5.83V56c0,2.63-.09,5.63-.19,8.46-.1,1.52-.57,1.88-1.5,1.88a35.84,35.84,0,0,1-4.66.14,50.7,50.7,0,0,1-5.08-.14c-2.06,0-2.24-1.32-2.44-3-.19-2.81-.19-12.4-.19-16.73V33c0-5.17.19-24.74.39-29.81,0-1.32-.39-1.69-1-1.69a8.12,8.12,0,0,0-2.06.56c-3,1.88-10.35,4.33-14.11,5.46-.58.18-.75.55-.75.74V9.37c0,.4,0,.58.56.78l1.88.55c3,1,4.61,2.45,4.79,5.65.19,2.44.38,10.53.38,17.11V62.91c0,2.64-1.42,3.38-2.35,3.38,0,0-1.41.14-4.86.14s-4.79-.14-4.79-.14c-2.08,0-2.28-1.32-2.46-3-.19-2.81-.19-12.4-.19-16.73V33c0-5.17.19-24.74.37-29.81,0-1.32-.37-1.69-.92-1.69a8.4,8.4,0,0,0-2.08.56c-3,1.88-10.34,4.33-14.1,5.46-.57.18-.76.55-.76.74V9.37c0,.4,0,.58.58.78l1.87.55c3,1,4.6,2.45,4.79,5.65.2,2.44.38,10.53.38,17.11V62.91c0,2.64-1.4,3.38-2.35,3.38H1470c-1.11,0-1.31.19-1.31.77v1.78c0,.56.2.94.75.94.38,0,18.41-.18,36.15-.18,17.11,0,33.94.18,34.31.18.57,0,.76-.38.76-.94v-1.9c0-.46-.19-.65-1-.65"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1420.07,56c-.61-.58-1.7-1.55-1.78-1.64s-.43,0-.53.1c-4.18,5.3-12,9.89-21.66,9.89-4.52,0-10-.76-16.17-7.35-7-7.53-8.67-18.62-8.67-24.46,0-13.52,6.22-27.08,21.25-27.08,7.35,0,12.25,3,16.38,7a33.44,33.44,0,0,1,7.34,12.21c.28.76.56,1.14,1,1L1419,25c.38-.09.48-.39.38-.95-.38-2.82-2.26-17.86-2.26-19.54,0-1.33-.18-1.52-1.32-1.52s-1.32,0-1.49.58L1413.58,6c-.18.74-.56.74-1.88,0a41,41,0,0,0-19-4.52c-11.29,0-19.37,4.52-24.46,10a36.5,36.5,0,0,0-9.59,25.2c0,8.28,3.2,19.19,10.16,25.41,5.84,5.26,12.6,8.84,24.65,8.84,12.38,0,21-6.64,26.73-14a.78.78,0,0,0-.13-.89"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
    <path
      d="M1533.94,17a5.74,5.74,0,1,1-5.74-5.74,5.74,5.74,0,0,1,5.74,5.74"
      transform="translate(-1314.29 47.83)"
      fill="#fff"
    />
  </svg>
);

export const SearchIcon = (color: string | undefined) => (
  <svg
    id="searchIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.9 94.5"
  >
    <path
      id="Path_3886"
      stroke={ color }
      fill="none"
      strokeWidth="4"
      d="M35.7-36.09v16h16"
      transform="translate(32.96 37.34)"
    />
    <path
      id="Path_3887"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M-16.3-22.12V-32.86a3.2,3.2,0,0,1,3.15-3.23H35.7a2.33,2.33,0,0,1,1.8.94L50.75-22.07a2.58,2.58,0,0,1,1,2v72.8a3.2,3.2,0,0,1-3.17,3.2H-13.15a3.18,3.18,0,0,1-3.15-3.2V42.07"
      transform="translate(32.96 37.34)"
    />
    <path
      id="Path_3888"
      stroke={ color }
      fill="none"
      strokeWidth="4"
      d="M3.6,27.41A19.74,19.74,0,1,0-16.13,7.67,19.74,19.74,0,0,0,3.6,27.41Z"
      transform="translate(32.96 37.34)"
    />
    <path
      id="Path_3889"
      stroke={ color }
      fill="none"
      strokeWidth="4"
      d="M-10.34,21.62-32.07,43.35"
      transform="translate(32.96 37.34)"
    />
  </svg>
);

export const PINIcon = (color: string | undefined) => (
  <svg
    id="clickIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 94.5 93.99"
  >
    <path
      id="Path_4109"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M52.11,29l3.88.73V-31.65L31.45-35.78,7.76-24.86l-20-9.21L-36-25V36.31l23.8-9L2.13,33.75"
      transform="translate(37.26 37.08)"
    />
    <path
      id="Path_4110"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M-12.21-22.44V16"
      transform="translate(37.26 37.08)"
    />
    <path
      id="Path_4111"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M7.76-14.76V-.09"
      transform="translate(37.26 37.08)"
    />
    <path
      id="Path_4112"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M31.88-25.17V-5.74"
      transform="translate(37.26 37.08)"
    />
    <path
      id="Path_4114"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M25.86,24.24a4.12,4.12,0,1,0-4.15-4.12,4.12,4.12,0,0,0,4.15,4.12Z"
      transform="translate(37.26 37.08)"
    />
    <path
      id="Path_4115"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M26,56.22S9.21,30.88,9.21,21.67A16.71,16.71,0,0,1,26,5c8.75,0,17.27,7.8,16.76,16.67-.25,4.34-2.94,10.4-5.57,15.6-2.47,4.89-4.88,8.84-4.88,8.84"
      transform="translate(37.26 37.08)"
    />
  </svg>
);

export const UploadIcon = (color: string | undefined) => (
  <svg
    id="uploadIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108.09 53.77"
  >
    <polygon
      stroke={ color }
      fill="none"
      strokeWidth="3"
      points="104.67 15.11 3.44 15.11 20.01 1.25 88.28 1.26 104.67 15.11"
    />
    <polyline
      stroke={ color }
      fill="none"
      strokeWidth="3"
      points="14.5 24.56 3.44 33.81 104.67 33.81 93.71 24.56"
    />
    <polyline
      stroke={ color }
      fill="none"
      strokeWidth="3"
      points="14.5 43.26 3.44 52.52 104.67 52.52 93.71 43.26"
    />
  </svg>
);

export const DrawIcon = (color: string | undefined) => (
  <svg
    id="drawIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88.62 94.1"
  >
    <line
      id="Line_20"
      stroke={ color }
      fill="none"
      strokeWidth="4"
      y1="92.85"
      x2="88.62"
      y2="92.85"
    />
    <path
      id="Path_4362"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11"
      transform="translate(34.31 37.14)"
    />
    <path
      id="Path_4363"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85"
      transform="translate(34.31 37.14)"
    />
    <circle
      id="Ellipse_2"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      cx="33.22"
      cy="52.86"
      r="5.67"
    />
    <line
      id="Line_21"
      fill="none"
      stroke={ color }
      strokeWidth="4"
      x1="3.37"
      y1="92.08"
      x2="30.5"
      y2="56.12"
    />
  </svg>
);

export const GlobeIcon = () => (
  <svg
    id="globeIcon"
    xmlns="http://www.w3.org/2000/svg"
    height="16px"
    viewBox="0 0 92.15 92.15"
  >
    <path
      id="Path_4355"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M9.65,54.74A44.84,44.84,0,1,1,39.76,43.12"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4356"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M10.22-34.91c12.11,0,21.92,20.07,21.92,44.82S22.33,54.74,10.22,54.74"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4357"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M9.19-34.91c-12.1,0-21.92,20.07-21.92,44.82S-2.91,54.74,9.19,54.74"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4358"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M9.65-36.06v92"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4359"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M54.44,11.72H-35.17"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4360"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M50.74-8.21H-31.44"
      transform="translate(36.42 36.16)"
    />
    <path
      id="Path_4361"
      stroke="#0c9ed9"
      fill="none"
      strokeWidth="4"
      d="M49,31.64H-29.69"
      transform="translate(36.42 36.16)"
    />
  </svg>
);

export const PdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="file-pdf"
    className="svg-inline--fa fa-file-pdf fa-w-12"
    role="img"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
    />
  </svg>
);

export const ExcelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="file-excel"
    className="svg-inline--fa fa-file-excel fa-w-12"
    role="img"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
    />
  </svg>
);
