import { InfoOutlined } from "@mui/icons-material";
import { Alert, Box, Slide, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

export const AlertError = ({ error, setError }:{error:string|null; setError:()=>void})=>{
  const [ checked, setChecked ] = useState(error !== null);

  useEffect(()=>{
    setTimeout(()=>setChecked(error !== null), 500);

    if (checked){
      setTimeout(()=>{
        setChecked(false);
        setError();
      }, 3500);
    }
  }, [ error, checked ]);

  if (error){
    return <Box sx={ { 
    
      position: "absolute",
      top: "60px",
      right: 0
    } }>
      <Slide in={ checked } direction="left" >
        <Alert 
          severity="error"
          sx={ {
            padding: "5px 20px ",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)"
          } }
        >{ error }
        </Alert> 
      </Slide>
    </Box>; 
  }
  return null;
};



export const SystemUpdate = ()=> {
  const {
    REACT_APP_NOTIFICATION_COLOR,
    REACT_APP_NOTIFICATION_ENABLED, 
    REACT_APP_NOTIFICATION_TITLE,
    REACT_APP_NOTIFICATION_MESSAGE 
  } = process.env;

  if (REACT_APP_NOTIFICATION_ENABLED !== "true"){
    return null;
  }
  
  return <Alert
    icon={ <InfoOutlined fontSize="inherit" sx={ { color: "#2e2e2e" } } /> }
    sx={ {   margin: "20px", alignItems: "center", backgroundColor: REACT_APP_NOTIFICATION_COLOR, color: "#2e2e2e", width: "100%" } }>
    <Typography variant="body1" sx={ { fontFamily: "Open Sans", marginBottom: "10px" } }>{ REACT_APP_NOTIFICATION_TITLE }</Typography>
    <Typography variant="caption" sx={ { fontFamily: "Open Sans" } }>{ REACT_APP_NOTIFICATION_MESSAGE }</Typography>
  </Alert>;
};
