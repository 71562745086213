import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import { Reducer } from "./Reducer";
import { FeaturedCountyLayer } from "../Classes/FeaturedCountyLayer";
import { FeaturedPropertyLayer } from "../Classes/FeaturedPropertyLayer";
import { AlertError } from "../Components/AlertDisplay";
import { ScoringType } from "../Interfaces/enumScoring";
import { IContext } from "../Interfaces/IContext";
import { IFilter } from "../Interfaces/IFilter";
import { IProject } from "../Interfaces/IProject";
import { IProjectTemplate } from "../Interfaces/IProjectTemplate";
import { ISiteAdvisorUser } from "../Interfaces/IUser";
import { IWorkspace } from "../Interfaces/IWorkspace";
import SiteAdvisorService from "../Services/siteAdvisor-service";


export const Context = React.createContext({} as IContext);

type ProviderProps = {
  auth0: any;
  history: any;
};

export type ProviderState = {
  service:SiteAdvisorService;
  featuredLayer: FeaturedCountyLayer | null;
  featuredPropertyLayer: FeaturedPropertyLayer | null;
  MSA: any[];
  filters: IFilter[];
  projects: IProject[];
  workspace: IWorkspace;
  user: ISiteAdvisorUser | null;
  error: string|null;
  userProject: IProject;
  selectedFeature: any;
  scoreType: ScoringType,
  activeLayers: string[];
  setSelectedFeature: (s: any) => void;
  setAllSelected: (featuredLayer: any) => void;
  setAllPropertiesSelected: (featuredPropertyLayer: any) => void;
  dispatch:(action:any)=>any;
};

class Provider extends Component<ProviderProps, ProviderState> {
  private siteAdvisorService = new SiteAdvisorService({
    history: this.props.history,
    auth0: this.props.auth0
  });

  projectTemplate = {} as IProjectTemplate;
  initialState: ProviderState = {
    service: this.siteAdvisorService,
    featuredLayer: null,
    featuredPropertyLayer: null,
    MSA: [],
    filters: [],
    projects: [],
    workspace: {} as IWorkspace,
    user: null,
    error: null,
    userProject: {} as IProject,
    selectedFeature: null as any | null,
    scoreType: ScoringType.RELATIVE,
    activeLayers: [ "featuredLayer", "featuredPropertyLayer" ] as string[],
    setSelectedFeature: (selectedFeature: any | null) => this.setState({ selectedFeature }),
    setAllSelected: (featuredLayer: any) => this.setState({ featuredLayer }),
    setAllPropertiesSelected: (featuredPropertyLayer: any) => this.setState({ featuredPropertyLayer }),
    dispatch: async(action:any) => {
      const res = await Reducer(this.state, action);

      this.setState({ ...this.state, ...res });
      return res;
    }
  };

  state = this.initialState;

  componentDidMount = async () => {
    const user = await this.siteAdvisorService.getUserInfo(); // once token has been retrieved from Auth0, then get user from SiteAdvisor Api.

    this.setState({ user });
    /* eslint-disable no-console */
    if (process.env.NODE_ENV === "development") {
      console.log(this);
    }
    /* eslint-disable no-console */
  };

  resetState = () => {
    const { MSA, projects, user } = this.state;

    this.setState({ ...this.initialState, MSA, projects, user });
  };


  render() {
    return (
      <Context.Provider
        value={ {
          ...this.state,
          ...this
        } }
      >
        <AlertError error={ this.state.error } setError={ ()=> this.setState({ error: null }) }/>
        { this.props.children }
      </Context.Provider>
    );
  }
}

export default withAuth0(Provider);

export const Consumer = Context.Consumer;


