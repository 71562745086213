import { useAuth0 } from "@auth0/auth0-react";
import { Map } from "leaflet";
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IMapContext } from "../Interfaces/IMapContext";
import AtlasService from "../Services/atlas-service";

export const MapContext = createContext({} as IMapContext);

const MapProvider = ({ children }: any) => {
  const auth0 = useAuth0();
  const history = useHistory();
  const atlasService = new AtlasService({ history, auth0 });
  const [ map, setMap ] = useState(null as Map | null);
  const [ mapBounds, setMapBounds ] = useState({} as any);
  const [ basemapToken, setBasemapToken ] = useState<any|null>(null);
  const [ atlasToken, setAtlasToken ] = useState<any|null>(null);
  const [ basemap, setBasemap ] = useState({
    id: "colliers_gray",
    key: [ "855a4d90d0f34769aa5f9672ef65e5a3" ]
  } as any);

  const setMapProperties = (map: Map|null) => {
    setMap(map);
    if (map) { 
      setMapBounds(map.getBounds());
    }
  };

  const atlasApiToken = useCallback(async() => {
    const token = await atlasService.getAtlasToken();

    setAtlasToken(token);
  }, [ atlasService ]);
  const auth2Token = useCallback(async() => {
    const token = await atlasService.getoAuth2Token();

    setBasemapToken(token);
  }
  , [ atlasService ]);

  useEffect(() => {
    if (!atlasToken){
      atlasApiToken();
    }
    if (!basemapToken){
      auth2Token();
    }
  }, [ atlasToken ]);

  return (
    <MapContext.Provider
      value={ {
        map,
        mapBounds,
        basemap,
        atlasToken,
        basemapToken,
        setMapProperties,
        setBasemap,
        atlasService
      } }
    >
      { children }
    </MapContext.Provider>
  );
};

export default MapProvider;
