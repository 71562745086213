import { Auth0Provider, User, useAuth0 } from "@auth0/auth0-react";
import { RegisterUser } from "@colliers-international/usage-tracker";
import { Suspense, lazy as lazyLoad } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ResponsiveAppBar from "./Components/Layout/AppBar";
import { Splash } from "./Components/Splash";
import Provider from "./Context/Context";
import MapProvider from "./Context/MapContext";
// import { RegisterUser } from "./matomo";
import "./Styles/app.scss";
import "./Styles/layout.scss";

const SiteDashboard = lazyLoad(() => import("./Pages/SiteDashboard/SiteDashboard"));
const Analysis = lazyLoad(() => import("./Pages/Analysis/Analysis"));
const CreateNewAnalysis = lazyLoad(() => import("./Pages/Analysis/CreateNewAnalysis"));


function App() {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    return history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };
  const domain: string = process.env.REACT_APP_AUTH_DOMAIN as string;
  const clientId: string = process.env.REACT_APP_AUTH_CLIENTID as string;
  const audience: string = process.env.REACT_APP_AUTH_AUDIENCE as string;

  return (
    <>
      <Auth0Provider
        domain={ domain }
        clientId={ clientId }
        audience={ audience }
        redirectUri={ window.location.origin }
        onRedirectCallback={ onRedirectCallback }
      >
        <AuthenticatedRoutes />
      </Auth0Provider>
    </>
  );
}

export default App;

const AuthenticatedRoutes = () => {
  const { isLoading, isAuthenticated, user, loginWithRedirect, error } = useAuth0();
  const history = useHistory();

  if (isLoading) {
    return <div style={ { height: "100vh" } }><Splash message="loading..." /></div>;
  }
  if (error) {
    return <div>Oops... { error.message }</div>;
  }
  if (isAuthenticated) {
    return (
      <Provider history={ history }>
        <MapProvider history={ history }>
          <UsageTracker user={ user }>
            <div className="appDiv">
              <ResponsiveAppBar />
              <Suspense fallback={ <Splash message="loading..." /> }>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/analysisList" />
                  </Route>
                  <Route path="/analysisList" render={ () => <Analysis /> } />
                  <Route
                    path="/createNewAnalysis"
                    render={ () => <CreateNewAnalysis /> }
                  />
                  <Route
                    path="/sitedashboard/:id"
                    render={ () => <SiteDashboard /> }
                  />
                </Switch>
              </Suspense>
            </div>
          </UsageTracker>
        </MapProvider>
      </Provider>
    );
  } else {
    loginWithRedirect();
    return null;
  }
};



const UsageTracker = ({ user, children }:{user?: User;children:JSX.Element}) => {
  const { REACT_APP_FULLSTORY_ORG, REACT_APP_MATOMO_SITEID, REACT_APP_TRACKING } = process.env;

  if (!REACT_APP_FULLSTORY_ORG || !REACT_APP_MATOMO_SITEID){
    return children;
  }

  const authUser = {
    userId: user?.sub as string,
    displayName: user?.name as string,
    email: user?.email as string,
    userVars: { ...user }
  };

  return  <RegisterUser 
    enableTracking={ REACT_APP_TRACKING === "true" }
    matomoSiteId={ parseInt(REACT_APP_MATOMO_SITEID, 10) }
    fullstoryOrgId={ REACT_APP_FULLSTORY_ORG }
    user={ authUser }
  >
    { children }
  </RegisterUser>;

};
