import { FeaturedItem } from "../Classes/FeatureBase";
import { ScoringType } from "../Interfaces/enumScoring";
import { IFeatureCounty } from "../Interfaces/IFeatureCounty";
import { IFeatureGeoJson } from "../Interfaces/IFeatureGeoJson";
import { IFeatureProperty } from "../Interfaces/IFeatureProperty";
import { IScoreWeight } from "../Interfaces/IScoreWeight";

function filteredNull(item:FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>, field:string){
  return item.data.properties[field] !== null && item.data.properties[field] !== undefined;
}

export function updateEffectiveWeights(scores: IScoreWeight[], categories:string[]) {
  categories.forEach(category => {
    const filteredScores = scores.filter(sf => sf.category === category);
    const total = filteredScores.map(sm => parseInt(sm.value)).reduce((a, b) => a + b);
    const scoreAvg = total / filteredScores.length;

    filteredScores.forEach(score => {
      score.weight = score.baseWeight * (parseInt(score.value) / scoreAvg);
      return score.weight;
    });
    const summedWeights = filteredScores.map(score => score.weight).reduce((a:any, b) => a + b);

    filteredScores.forEach(score => {
      if (score.weight && summedWeights) { 
        score.effectiveWeight = score.weight / summedWeights;
      }
    });
  }); 
}
  
export function updateMinMaxRange(features:FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], scores:IScoreWeight[], categories:string[]) {
  return scores.filter(s => categories.includes(s.category)).map(score => {
    let field = score.field.split("_score")[0];

    // Do not include null values when finding min,max and range.
    const values = features.filter(item =>filteredNull(item, field)).map(item => {
      return parseFloat(item.data.properties[field].toString());
    });
    const min = Math.min(...values);
    const max = Math.max(...values);
  
    score.min = min;
    score.max = max;
    score.range = max - min;
    return score;
  });
}

export function nationalMinMaxScores( features:FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], scores:IScoreWeight[], categories:string[]) {
  scores.forEach((score) => {
    if (score.category !== "real_estate" ) { 
      score.min = score.national_range[0];
      score.max = score.national_range[1];
      score.range = score.national_range[1] - score.national_range[0];
    } else {
      updateMinMaxRange(features, scores.filter(s => s.category === "real_estate"), categories);
    }
  });
}

export function updateIndividualFieldScores(features: FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], scores: IScoreWeight[], categories: string[]) {
  scores.filter(s => categories.includes(s.category)).forEach(score => {

    // Do not generate score for null values.
    features.filter(item => filteredNull(item, score.field.split("_score")[0])).forEach(item => {
      let field = score.field.split("_score")[0];
    
      const value = parseFloat(item.data.properties[field].toString());

      let fieldScore = 0;

      if (score.min !== undefined && score.range) {
        fieldScore =  ((value - score.min) / score.range);
      }
      if (score.inverted ) {
        fieldScore = 1 - fieldScore;
      }
      
      item.data.properties[score.field] = fieldScore;
    });
  });
}

export function updateCategoryScore(features: FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], scores: IScoreWeight[], categories: string[]) {
  categories.forEach(category => {
    features.forEach((item, i) => { 
      const itemCategoryValue = scores
        .filter(s => category === s.category)
        .map(score => {

          // fields with null values are not included in category score calculation.
          if (score.effectiveWeight && filteredNull(item, score.field.split("_score")[0])) {
            return (parseFloat(item.data.properties[score.field].toString() )) * score.effectiveWeight;
          }
          return 0;
        });
      const catScore = itemCategoryValue.length ? itemCategoryValue.reduce((a, b) => a + b) : 0;

      item.data.properties[`${category}_score`] = catScore ;
    });
  });
}

export function updateTotalScores(features: FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], categories: {group: string; weight: number}[]) {
  features.forEach(item => {
    const val = categories.map(category => {
      return (parseFloat(item.data.properties[`${category.group}_score`].toString() )) * category.weight;
    }).reduce((a, b) => a + b);

    item.data.properties.total_score = val;
  });
}


export function calculateScores(features:FeaturedItem<IFeatureCounty | IFeatureGeoJson<IFeatureProperty>>[], scores:IScoreWeight[], categories:{group:string, weight:number}[], scoreExtent:ScoringType){
  const categoryNames = categories.map(c => c.group);
  const calcScores = scores.map(s => {
    const baseWeight = industrialScores.find(i => i.field === s.field)?.baseWeight;

    return { ...s, baseWeight: baseWeight ? baseWeight : .5 };
  });

  updateEffectiveWeights(calcScores, categoryNames);
  if (scoreExtent === ScoringType.NATIONAL) {
    nationalMinMaxScores(features, calcScores, categoryNames);
  } else { 
    updateMinMaxRange(features, calcScores, categoryNames);
  }
  updateIndividualFieldScores(features, calcScores, categoryNames);
  updateCategoryScore(features, calcScores, categoryNames);
  updateTotalScores(features, categories);
  
  // multiply scores by 100
  scores.filter(s => categoryNames.includes(s.category)).forEach(score => {
    features.forEach(item => {
      if (item.data.properties[score.field.split("_score")[0]] !== null) {
        

        const value = parseFloat((item.data.properties[score.field] || 0).toString());
        
        item.data.properties[score.field] = Math.round(value * 100);
      } else {
        item.data.properties[score.field] = "N/A";
      }
    });
  });
  categoryNames.forEach(category => {
    features.forEach(item => {
      const val = item.data.properties[`${category}_score`];

      item.data.properties[`${category}_score`] = Math.round(parseFloat(val.toString()) * 100);
    });
  });
  features.forEach(item => {
    item.data.properties.total_score = Math.round(item.data.properties.total_score * 100);
  });
}



const industrialScores = [
  {
    field: "net_commuters_score",
    baseWeight: 0.10
  },
  {
    field: "seasonal_part_time_pct_score",
    baseWeight: 0.10
  },
  {
    field: "warehouse_workers_score",
    baseWeight: 0.10
  },
  {
    field: "unemployment_rate_score",
    baseWeight: 0.05
  },
  {
    field: "location_quotient_score",
    baseWeight: 0.10
  },
  {
    field: "electric_score",
    baseWeight: 0.10
  },
  {
    field: "real_estate_tax_score",
    baseWeight: 0.07
  },
  {
    field: "personal_property_tax_score",
    baseWeight: 0.08
  },
  {
    field: "total_population_score",
    baseWeight: 0.20
  },
  {
    field: "labor_participation_rate_score",
    baseWeight: 0.05
  },
  {
    field: "crime_index_score",
    baseWeight: 1.00
  },
  {
    field: "under_35k_pct_score",
    baseWeight: 0.25
  },
  {
    field: "wages_score",
    baseWeight: 0.75
  },
  {
    field: "union_elections_per_100_score",
    baseWeight: 0.05
  },
  {
    field: "building_quality_score",
    baseWeight: 0.15
  },
  {
    field: "clear_height_minimum_score",
    baseWeight: 0.35
  },
  {
    field: "dock_doors_score",
    baseWeight: 0.2
  },
  {
    field: "auto_parking_score",
    baseWeight: 0.1
  },
  {
    field: "trailer_parking_score",
    baseWeight: 0.1
  },
  {
    field: "expandable_space_score",
    baseWeight: 0.1
  }
];
