import chroma from "chroma-js";
import { FeaturedItem } from "./FeatureBase";
import { ScoringType } from "../Interfaces/enumScoring";
import { IFeatureCounty } from "../Interfaces/IFeatureCounty";
import { IFeatureProperties } from "../Interfaces/IFeatureProperties";
import { IProject } from "../Interfaces/IProject";
import { IScoreWeight } from "../Interfaces/IScoreWeight";
import { IToggleFeatures } from "../Interfaces/IToggleFeatures";
import { IWorkspace } from "../Interfaces/IWorkspace";
import { calculateScores } from "../Utils/scoreCalculate";
import {  generateColor } from "../Utils/Utils";

export class FeaturedCountyLayer {
  featuredCounties: FeaturedItem<IFeatureCounty>[] = [];
  unselectedFeatures:FeaturedItem<IFeatureCounty>[] = [];
  toggleFeatures: IToggleFeatures[] = [];
  styleByField: any = "total_score";
  limits: number[] = [];
  scale: string[] = [];
  constructor({ featuredItems, workspace, userProject }: { featuredItems: IFeatureCounty[]; workspace: IWorkspace; userProject:IProject}) {
    this.toggleFeatures = workspace.toggleFeatures;
    this.buildFeaturedCounties(featuredItems, userProject);
    this.calculateScoreWeights(userProject.scoreWeights, ScoringType.RELATIVE);
    this.generateColorRange(this.styleByField);
  }

  buildFeaturedCounties(featuredItems:IFeatureCounty[], userProject:IProject) {
    this.featuredCounties = featuredItems
      .filter((f) => !userProject.unselectedFeatures?.find((u) => u === f.properties.fipsid))
      .map((data) => new FeaturedItem(data, data.properties.fipsid));
    this.unselectedFeatures = featuredItems
      .filter((f) => userProject.unselectedFeatures?.find((u) => u === f.properties.fipsid))
      .map((data) => new FeaturedItem(data, data.properties.fipsid));
  }

  calculateScoreWeights(scores: IScoreWeight[], scoreType:ScoringType) {
    const categories = [
      {
        group: "labor",
        weight: 0.5
      },
      {
        group: "cost",
        weight: 0.4
      },
      {
        group: "risk",
        weight: 0.1
      }
    ];

    calculateScores(this.featuredCounties, scores, categories, scoreType);
    this.generateColorRange(this.styleByField);
  }
  
  minMax(items: any[], field: string) {
    return items.reduce((acc, val) => {
      acc[0] =
          acc[0] === undefined || val.properties[field] < acc[0]
            ? val.properties[field]
            : acc[0];
      acc[1] =
          acc[1] === undefined || val.properties[field] > acc[1]
            ? val.properties[field]
            : acc[1];
      return acc;
    }, []);
  }
  
  generateColorRange(field: string) {
    const totalScore = field === "total_score"; // if the field is 'total_score', then use predefined colors ans steps.
    const values = this.featuredCounties
      .filter((v, i, a)=> a.findIndex(t=>(t.data.properties[field as keyof IFeatureProperties] === v.data.properties[field as keyof IFeatureProperties])) === i && v.id)
      .map((c) => c.data.properties[field as keyof IFeatureProperties])
      .map(v => parseFloat(v.toString()));
    const numArr = totalScore ? [ 0, 46, 57, 99 ] : chroma.limits(values, "q", 4); // values are returned smallest to largest.

    numArr[numArr.length - 1] = Math.ceil(numArr[numArr.length - 1]); // increase highest value by 1 to include all values in scale.
    const colorArr = totalScore
      ? [ "#F0190C", "#F8B720", "#1D9800" ]
      : chroma
        .scale(this.toggleFeatures.find((t) => t.field === field)?.colors)
        .colors(numArr.length - 1);

    if (totalScore) {
      this.featuredCounties.map((item) => {
        return (item.display.color = generateColor({
          properties: item.data.properties,
          field
        }));
      });
    } else {
      this.featuredCounties.map((item) => {
        return (item.display.color = generateColor({
          properties: item.data.properties,
          field,
          colorArr,
          numArr
        }));
      });
    }
    this.scale = colorArr.reverse(); // need values to appear from greatest to smallest in legend.
    this.limits = numArr.reverse(); // need values to appear from greatest to smallest in legend.
  }
}
  
