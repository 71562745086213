import { User } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import { 
  Backdrop,
  Button,
  Divider,
  Paper,
  Typography
} from "@mui/material";
import moment from "moment";
import  { useContext } from "react";
import {  useHistory, useRouteMatch } from "react-router-dom";
import { Context } from "../Context/Context";

export const TermsConds = ({ style }:{style?:any})=> (
  <Paper elevation={ 0 } sx={ { padding: "0px 16px", marginBottom: 2 } }>
    <p style={ style }>
      SiteAdvisor was developed in partnership with the Atlanta CSSS team, leveraging their site selection tool. The following fee share guidelines are in effect for the use of SiteAdvisor;
    </p>
    <ol style={ { marginTop: "10px", ...style } }>
      <li style={ { marginBottom: "10px" } }>
        If SiteAdvisor is used in a pitch only, without involvement from the Atlanta CSSS team or other SiteAdvisor super-users, then there is no fee sharing.
      </li>
      <li style={ { marginBottom: "10px" } }>
        If SiteAdvisor is used to pitch with participation from the Atlanta CSSS team or other SiteAdvisor super-users and the assignment is won and executed without further involvement of Atlanta CSSS team or the use of SiteAdvisor super-users, Atlanta CSSS team’s share of the fees is 5% of the total gross fees.
      </li>
      <li style={ { marginBottom: "10px" } }>
        If SiteAdvisor is used to pitch, win, and execute an assignment for a client and the Atlanta CSSS team is not involved, then the Atlanta CSSS team will be paid 5% of the total gross fees, not to exceed $25,000.
      </li>
      <li style={ { marginBottom: "10px" } }>
        If the Atlanta CSSS team or other SiteAdvisor super-users are engaged for an assignment beyond just the use of SiteAdvisor, the fee sharing arrangement will be subject to MCA guidelines and fairly agreed upon at the time of assignment, in so far as the assignment responsibilities and actions will be delineated.
      </li>
    </ol>
  </Paper>

);

export const Terms = ()=> (
  <>
    <Typography variant="h4" sx={ { margin: "20px 0px", display: "flex", justifyContent: "center" } }>
      Terms and Conditions of use for SiteAdvisor
    </Typography>
    <TermsConds />
  </>
);


export const SignedTerms = ({ user, acceptedDate, close }:{user:User, acceptedDate:string|null;  close: () => void; })=> (
  <>
    <div
      style={ {
        display: "flex",
        minHeight: "25px",
        position: "static",
        justifyContent: "space-between",
        padding: "0px 5px 5px 5px",
        color: "#777777"
      } }
    >
      <div style={ { display: "flex" } }>
        <HelpCenterRoundedIcon style={ { color: "#55555" } } />
        <Typography
          variant="body1"
          sx={ { lineHeight: "1.5", marginLeft: "2px" } }
        >
          Terms and Conditions of use for SiteAdvisor
        </Typography>
      </div>
      <CloseIcon onClick={ close } />
    </div>
    <Divider />
    <div style={ { maxWidth: "425px", overflow: "auto", maxHeight: "80vh"  } }>
      <Typography variant="h6" sx={ { padding: "0px 16px", display: "flex", justifyContent: "center" } }>
        Terms Accepted by 
      </Typography>
      <Typography variant="h6" sx={ { padding: "0px 16px", display: "flex", justifyContent: "center" } }>
        { user.name } 
      </Typography>
      <Typography variant="h6" sx={ { padding: "0px 16px", paddingBottom: 0, display: "flex", justifyContent: "center" } }>
        on { moment(acceptedDate).format("MM-DD-YYYY") }
      </Typography>
      <TermsConds style={ { fontSize: "12px" } } />
    </div>
  </>

);

