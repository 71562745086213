import BaseHttpService from "./base-http";

class AtlasService extends BaseHttpService {
  BASE_URL = process.env.REACT_APP_GEOCODEURL;
  atlasToken!: string;


  async geoCode(address: string) {
    return await this.get(
      `https://colliers-hydra-api.herokuapp.com/proxy?https://portal.atlas.colliers.com/portal/sharing/servers/ba4a6d63b87d42308a749b0e72f10eca/rest/services/NorthAmerica/GeocodeServer/findAddressCandidates?SingleLine=${address.replace(
        "&",
        "and"
      )}&f=pjson`
    );
  }

  async reverseGeoCode(latlng: [number, number]) {
    // return await this.get(`https://portal.atlas.colliers.com/portal/sharing/servers/ba4a6d63b87d42308a749b0e72f10eca/rest/services/NorthAmerica/GeocodeServer/reverseGeocode?f=pjson&location=${latlng}&featureTypes=PointAddress,StreetAddress,StreetInt,Postal,Locality&token=${this.atlasToken}`)
    return await this.get(
      `${this.BASE_URL}/simplereversegeocode?f=pjson&latitude=${latlng[0]}&longitude=${latlng[1]}`
    );
  }

  async getSuggestions(suggestValue: string) {
    return await this.get(
      this.BASE_URL +
        "/eproxy/geocode/suggest?text=" +
        suggestValue +
        "&f=pjson"
    );
  }

  async getAtlasToken():Promise<string> {
    const res = await this.get("https://colliers-boom-api.herokuapp.com/getAtlasToken");

    if (res){
      return res.data.token;
    }
    return "";
  }
 
  async getoAuth2Token():Promise<string> {
    const res = await this.get(" https://colliers-hydra-api.herokuapp.com/getoAuth2Token");

    if (res){
      return res.data;
    }
    return "";
  }

  async getGeoJson() {
    // return await this.get("https://portal.atlas.colliers.com/server/rest/services/Hosted/Intermodal_Ramps/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&having=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnCentroid=false&sqlFormat=none&resultType=&f=geojson");
  }
}

export default AtlasService;
