import React from "react";
import { Logo } from "./Icons";
import "./../Styles/splash.scss";

export function Splash(props: { message: string | React.ReactElement }) {
  const { message = "" } = props;

  return (
    <div className="splash">
      <div className="splash_content">
        <Logo />
        <label className="splash_content-message">{ message }</label>
      </div>
    </div>
  );
}
