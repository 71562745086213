/* eslint-disable no-case-declarations */
import { acceptTerms, createNewAnalysis, deleteProject, loadMSAs, loadProjects, loadSiteDashboard, resetFilters, resetScores, saveProject, setActiveLayers, updateFilters, updateLayerStyle, updateProjectMSA, updateProperties, updateScore, updateUnSelected } from "./Actions";
import { ProviderState } from "./Context";

export const Reducer = async(ctx: ProviderState, action: { type: any; payload:any })=>{
 
  switch (action.type) {   

  case "SET_ACTIVE_LAYERS":
    const activeLayers = setActiveLayers(ctx, action.payload);

    return { ...ctx, activeLayers };
      
  case "UPDATE_SCORE":
    const { scores, scoreType } = action.payload;
    const score = updateScore(ctx, scores, scoreType );
        
    return { ...ctx, ...score };
        
  case "UPDATE_LAYER_STYLE": {
    return updateLayerStyle(ctx, action.payload);
  }

  case "UPDATE_FILTERS": {
    return updateFilters(ctx, action.payload);
  }

  case "UPDATE_PROPERTIES": {
    return updateProperties(ctx, action.payload);
  }

  case "UPDATE_UNSELECTED": {
    const { unselected, type } = action.payload;

    return updateUnSelected(ctx, unselected, type);
  }

  case "CREATE_ANALYSIS": {
    return createNewAnalysis(ctx, action.payload);
  }

  case "RESET_FILTERS": {
    return resetFilters(ctx);
  }

  case "RESET_SCORES": {
    return resetScores(ctx);
  }

  case "LOAD_MSA": {
    return loadMSAs(ctx);
  }

  case "LOAD_PROJECTS":{
    return await loadProjects(ctx);
  }

  case "LOAD_DASHBOARD":{
    return await loadSiteDashboard(ctx, action.payload);
  }

  case "SAVE_PROJECT":{
    return await saveProject(ctx);
  }

  case "DELETE_PROJECT":{
    return await deleteProject(ctx, action.payload);
  }
  case "ACCEPT_TERMS":{
    return await acceptTerms(ctx, action.payload);
  }
  case "UPDATE_MSA":
    const { id, updatedMSAs } = action.payload;
    const updatedMSA = await updateProjectMSA(ctx,  id, updatedMSAs );

    if (updatedMSA){
      return { ...ctx, ...updatedMSA };
    }
    return ctx;

  default:
    return ctx;
  }
};
