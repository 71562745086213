import CloseIcon from "@mui/icons-material/Close";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import {
  Divider,
  List,
  ListItemText,
  Typography
} from "@mui/material";
import React from "react";

type Props = {
  close: () => void;
};

export const HelpPage = (props: Props) => {
  return (
    <>
      <div
        style={ {
          display: "flex",
          minHeight: "25px",
          position: "static",
          justifyContent: "space-between",
          padding: "0px 5px 5px 5px",
          color: "#777777"
        } }
      >
        <div style={ { display: "flex" } }>
          <HelpCenterRoundedIcon style={ { color: "#55555" } } />
          <Typography
            variant="body1"
            sx={ { lineHeight: "1.5", marginLeft: "2px" } }
          >
            Help
          </Typography>
        </div>
        <CloseIcon onClick={ props.close } />
      </div>
      <Divider />
      <List
        sx={ {
          width: "100%",
          maxWidth: 375,
          bgcolor: "background.paper",
          padding: "5px 10px"
        } }
      >
        <div style={ { overflow: "auto", maxHeight: "80vh" } }>
          { helperJson.map((h) => {
            return (
              <>
                <Typography
                  sx={ {
                    color: "#25408f",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderBottom: "3px solid #25408f",
                    padding: "5px 0px"
                  } }
                >
                  { h.title }
                </Typography>
                { h.group.map((g) => (
                  <>
                    <ListItemText
                      primary={
                        <Typography
                          sx={ {
                            color: "#25408f",
                            fontSize: "14px",
                            fontWeight: "bold",
                            paddingBottom: "5px"
                          } }
                        >
                          { g.name }
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={ { display: "inline" } }
                            component="span"
                            variant="body2"
                            color="text.default"
                          >
                            { g.description }
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <Divider sx={ { paddingBottom: "5px" } } />
                  </>
                )) }
              </>
            );
          }) }
        </div>
      </List>
    </>
  );
};

interface IHelperGroup {
  name: string;
  description: string;
}
interface IHelperJson {
  title: string;
  group: IHelperGroup[];
}

const helperJson: IHelperJson[] = [
  {
    title: "Scoring Definitions - Risk",
    group: [
      {
        name: "Crime Rate",
        description:
          "Property and personal crime rate indexed to US average. US average = 100"
      }
    ]
  },
  {
    title: "Scoring Definitions - Labor",
    group: [
      {
        name: "Workforce",
        description: "Total population in an area ages 16-64"
      },
      {
        name: "% of Households Earning < $35k",
        description:
          "Total share of households in a market where the median average income is less than $35,000 per year"
      },
      {
        name: "Net Commuters",
        description:
          "For a given geography and occupation, number of residents with that occupation minus the number of workers in that occupation. A positive number means the area is a net exporter of workers."
      },
      {
        name: "% Part-Time Seasonal Workers",
        description:
          "The share of workers in a location that are working below 14 hours weekly."
      },
      {
        name: "Labor Force Participation Rate",
        description:
          "The share of workers in a location 16 years or older that are working or actively looking for work."
      },
      {
        name: "Unemployment Rate",
        description:
          "The share of unemployed workers compared to the total potential labor force."
      },
      {
        name: "Number of Order Pickers",
        description:
          "The total number of order picker workers estimated in a location."
      },
      {
        name: "Location Quotient",
        description:
          "Concentration of order pickers in an area relative to the US average. An LQ >1 means an above average concentration."
      },
      {
        name: "Union Elections",
        description:
          "Number of warehouse-related labor union certifications elections per 100,000 workers."
      }
    ]
  },
  {
    title: "Scoring Definitions - Cost",
    group: [
      {
        name: "Electricity",
        description: "Average cost per kWh for industrial customers."
      },
      {
        name: "Real Estate Tax",
        description: "Tax per $1,000 of assessed real estate value."
      },
      {
        name: "Personal Property Tax",
        description: "Tax per $1,000 of assessed personal property value."
      }
    ]
  },
  {
    title: "Scoring Definitions - Real Estate",
    group: [
      {
        name: "Building Quality",
        description:
          "A qualitative measurement of how modern a property is and how many features / amenities are available. Derived from the year the property was built and the building class."
      },
      {
        name: "Clear Heights Minimum",
        description:
          "The minimum vertical distance from the ground or surface level to steel. A larger clear height minimum is typically considered better than a lower one."
      },
      {
        name: "Dock Doors",
        description:
          "Number of raised loading docks available at the site. Sites with more dock doors are scored higher based on this feature."
      },
      {
        name: "Auto Parking Spaces",
        description:
          "Number of available automobile parking spaces on the same lot as a site. A higher quantity of auto parking spaces is considered better than a lower one."
      },
      {
        name: "Trailer Parking Spaces",
        description:
          "Off-street space on the same lot as the site for the purpose of loading and unloading merchandise or materials. A higher quantity of trailer spaces is considered better than a lower one."
      },
      {
        name: "Expandable Space",
        description:
          "Available real estate for expansion. A higher amount of expandable square footage is considered better than a lower one."
      }
    ]
  },
  {
    title: "General",
    group: [
      {
        name: "How do I login in?",
        description: "Go to https://siteadvisor.colliers.com and sign in using the Colliers credentials provided to you."
      },
      {
        name: "What kind of device is recommended for SiteAdvisors?",
        description:
          "For the best experience, we recommend a desktop or laptop."
      },
      {
        name: "Can I share an analysis with a colleague?",
        description:
          "Coming Soon. You will have the ability to share an analysis with others."
      },
      {
        name: "How can I find more information about the scoring and weighting?",
        description:
          "On the filters and scoring panel, you can mouse over the info icons, a pop-up of definitions of each scoring will be appear. Secondly, you can reference this help file. "
      },
      {
        name: "How to do I export an anyalysis?",
        description:
          "On the charts, if you right click over the image, the option to save to your computer will appear."
      },
      {
        name: "Is the program free?",
        description: "Yes, SiteAdvisor is free for all Colliers Employees."
      },
      {
        name: "Is there an connection to CoStar?",
        description:
          "No, SiteAdvisor does not retrieve CoStar data. Currently, the property import function is the only way to add properties to SiteAdvisor."
      }
    ]
  },
  {
    title: "Importing",
    group: [
      {
        name: "Where do a find the Property Import Template?",
        description:
          "Within the app, on step 2, you will have the ability to download the property import template. You can also download template from the building icon on the menu bar."
      },
      {
        name: "What if I want to be add more fields to Property Import Template?",
        description:
          "Contact siteadvisor@colliers.com with the additional fields you would like added to the property template."
      },
      {
        name: "Do I have to upload 25 properties on the template?",
        description: "No. You can upload any amount 25 or less."
      },
      {
        name: "Do I have to enter the Latitude/Longitude on the Property Import Template?",
        description:
          "No. The Latitude and Longitude columns are available if you would like to pinpoint the locations to your preference, else the system will best guess on address provided in template. The system will generate the coordinates based on addresses."
      }
    ]
  },
  {
    title: "Selecting Markets",
    group: [
      {
        name: "My MSA is grouped with others, how do I isolate it?",
        description:
          "Load the grouped version of the MSA and you can filter out the unwanted counties in the filter panel."
      },
      {
        name: "How do I see what MSAs I selected?",
        description:
          "You can use the State picklist and toggle between states to see which MSA’s you have selected."
      },
      {
        name: "Can I do an analysis other than at the county level?",
        description:
          "Currently that feature availability will be on a future release. Please reach out to John.Warden@colliers.com can assist with a more detailed analysis."
      },
      {
        name: "Do we have data for non-MSA Counties?",
        description: "Yes. When selecting an MSA, choose \"Other non-metro areas.\""
      }
    ]
  }
];

